@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);

* {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  background: rgb(250,250,250);
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/*Exception made to hide scroll from input[type=number]*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a, a:visited, a:active {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus, input:-internal-autofill-selected {
  -webkit-text-fill-color: rgb(50, 110, 253);
  -webkit-box-shadow: 0 0 0 1000px rgba(0,0,0,0) inset !important;
  background-color: unset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  font-size: inherit;
}
